import { FC, ReactElement, cloneElement, createElement } from "react";
import { PageProps } from "gatsby";

import Layout from "@/ui/Layout/Layout";

interface IWrapPageElement {
  element: ReactElement;
  props: PageProps<object, { language: string }>;
}

const WrapPageElement: FC<IWrapPageElement> = ({ element, props }) => {
  if (element.props.children) {
    return cloneElement(
      element, // I18nextProvider
      element.props,
      cloneElement(
        element.props.children, // I18nextContext.Provider
        element.props.children.props,
        createElement(Layout, undefined, element.props.children.props.children)
      )
    );
  } else {
    return <Layout>{element}</Layout>;
  }
};

export default WrapPageElement;
