import { ReactNode } from "react";

import * as cls from "./NavLink.module.scss";
import { classNames } from "@/fsd/shared/lib/classNames/classNames";
import { Link } from "gatsby-plugin-react-i18next";

interface NavLinkProps {
  className?: string;
  name: string;
  href: string | undefined;
  icon?: ReactNode;
  externalLink?: boolean;
  onClick?: () => void;
}

export const NavLink = ({
  className,
  name,
  href,
  icon,
  externalLink,
  onClick,
}: NavLinkProps) => {
  return (
    <div>
      {!externalLink ? (
        <Link
          to={href || "#"}
          className={classNames(cls.navLink, {}, [className])}
          onClick={onClick}
          getProps={({ isCurrent }) => {
            return isCurrent ? { ["data-active"]: true } : {};
          }}
        >
          <span>
            {icon && <div className={cls.navLinkIcon}>{icon}</div>}
            {name}
          </span>
        </Link>
      ) : (
        <a href={href} target={"_blank"} className={cls.navLink}>
          <span>
            {icon && <div className={cls.navLinkIcon}>{icon}</div>}
            {name}
          </span>
        </a>
      )}
    </div>
  );
};
