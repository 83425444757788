import { FC, lazy } from "react";
import { AppShell } from "@mantine/core";
import LayoutFooter from "./LayoutFooter/LayoutFooter";
import { Header } from "@/fsd/widgets/Header";

interface LayoutProps {
  children: React.ReactNode;
}

import * as classes from "./Layout.module.css";

import CookieConsentCustom from "@/components/CookieConsent/CookieConsent";
import { DrawerStateContextProvider } from "@/context/DrawerStateContext/DrawerStateContext";

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <AppShell
      padding={0}
      classNames={{
        root: classes.root,
        main: classes.body,
        header: classes.header,
      }}
      header={{ height: { md: 100, xs: 75, lg: 125 } }}
    >
      <DrawerStateContextProvider values={false}>
        <Header />
      </DrawerStateContextProvider>
      <CookieConsentCustom />
      <AppShell.Main p={0}> {children}</AppShell.Main>
      <LayoutFooter />
    </AppShell>
  );
};

export default Layout;
