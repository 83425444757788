import { MantineThemeComponent, Container, rem } from "@mantine/core";
import { BREAKPOINTS } from "@/constants/mantine/breakpoints";

const CONTAINER_SIZES: Record<string, string> = {
  xxs: rem(300),
  xs: rem(400),
  sm: rem(1052),
  md: rem(1248),
  lg: rem(1352),
  xl: rem(1400),
};

export const ContainerComponent: Record<string, MantineThemeComponent> = {
  Container: Container.extend({
    vars: (_, { size, fluid }) => ({
      root: {
        "--container-size": fluid
          ? "100%"
          : size !== undefined && size in CONTAINER_SIZES
          ? CONTAINER_SIZES[size]
          : size !== undefined
          ? rem(size)
          : CONTAINER_SIZES.lg,

        [`@media (max-width: ${BREAKPOINTS.xs})`]: {
          paddingRight: 20,
          paddingLeft: 20,
        },
      },
    }),
  }),
};
