export const COLORS = {
  primary50: "#F9F5FF",
  primary600: "#7F56D9",
  primary700: "#6941C6",

  lightPurple: "#E5DDF7",

  blue50: "#EFF8FF",
  blue500: "#2E90FA",
  blue700: "#175CD3",

  indigo50: "#EEF4FF",
  indigo500: "#6172F3",
  indigo700: "#3538CD",

  pink50: "#FDF2FA",
  pink500: "#EE46BC",
  pink700: "#C11574",

  orange50: "#FFF4ED",
  orange500: "#EF6820",
  orange700: "#B93815",

  green50: "#ECFDF3",
  green500: "#12B76A",
  green700: "#027A48",

  black: "#1E1E1E",

  dark: "#7A7388",

  gray: "#CED0D4",
  gray50: "#F9FAFB",
  gray200: "#EAECF0",
  gray300: "#D0D5DD",
  gray400: "#98A2B3",
  gray600: "#475467",
  gray700: "#344054",
  gray800: "#1D2939",
  gray900: "#101828",
};
