import React from "react";
import { useTranslation } from "react-i18next";
import { UnstyledButton } from "@mantine/core";

import { classNames } from "@/fsd/shared/lib/classNames/classNames";
import { DropdownMenu } from "@/fsd/shared/ui/DropdownMenu";
import { NavLink } from "@/fsd/shared/ui/NavLink";

import * as cls from "./Navbar.module.scss";
import { navigation } from "@/routes/navigation";

interface NavbarProps {
  className?: string;
}

export const Navbar = ({ className }: NavbarProps) => {
  const { t, i18n } = useTranslation("APPCON");

  return (
    <div className={classNames(cls.Navbar, {}, [className])}>
      {navigation.map((item) => (
        <DropdownMenu
          label={
            <UnstyledButton fz={"inherit"}>
              <NavLink name={t(item.title)} href={item.href} />
            </UnstyledButton>
          }
          key={item.href}
        ></DropdownMenu>
      ))}
    </div>
  );
};
