import { useEffect, useLayoutEffect, useState } from "react";

import "./style.css";

import { run, setLanguage } from "vanilla-cookieconsent";
import { useI18next } from "gatsby-plugin-react-i18next";
import { apiLangs } from "@/constants/i18n";
import { getElementByXpath } from "@/utils/getElementsByXPath";

const translationEn = require("/locales/en/cookie.json");
const translationId = require("/locales/id/cookie.json");
const translationVn = require("/locales/vn/cookie.json");
const translationTh = require("/locales/th/cookie.json");
const translationMy = require("/locales/my/cookie.json");
const translationCn = require("/locales/cn/cookie.json");

declare global {
  interface Window {
    dataLayer: any;
  }
}

const isBrowser = typeof window !== "undefined";

function gtag() {
  if (isBrowser) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }
}

function setConsent(consent: any) {
  const consentMode = {
    functionality_storage: consent.functionality ? "granted" : "denied",

    analytics_storage: consent.analytics ? "granted" : "denied",

    ad_storage: consent.marketing ? "granted" : "denied",

    security_storage: consent.security ? "granted" : "denied",

    personalization_storage: consent.personalization ? "granted" : "denied",
  };

  // @ts-ignore
  gtag("consent", "update", consentMode);
  localStorage.setItem("consentMode", JSON.stringify(consentMode));
}

const CookieConsentCustom = () => {
  const { language } = useI18next();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    run({
      onModalReady: ({ modalName }) => {
        setReady(true);
      },

      onConsent: ({ cookie }) => {
        setConsent({
          necessary: cookie.categories.includes("necessary"),
          analytics: cookie.categories.includes("analytics"),
          security: cookie.categories.includes("security"),
          marketing: cookie.categories.includes("marketing"),
          functionality: cookie.categories.includes("functionality"),
          personalization: cookie.categories.includes("personalization"),
        });
      },

      guiOptions: {
        consentModal: {
          layout: "box inline",
          position: "bottom right",
          equalWeightButtons: false,
        },
        preferencesModal: {
          layout: "box",
          equalWeightButtons: false,
        },
      },

      categories: {
        necessary: {
          // necessary cookies
          enabled: true,
          readOnly: true,
        },
        functionality: {
          // functionality_storage: Enables storage that supports the functionality of the website or app e.g. language settings
          enabled: true,
        },
        analytics: {
          // analytics_storage: Enables storage (such as cookies) related to analytics e.g. visit duration
          enabled: true,
        },
        marketing: {
          // ad_storage: Enables storage (such as cookies) related to advertising
          enabled: true,
        },
        personalization: {
          // personalization_storage: Enables storage related to personalization e.g. video recommendations
          enabled: true,
        },
        security: {
          // security_storage: Enables storage related to security such as authentication functionality, fraud prevention, and other user protection
          enabled: true,
        },
      },
      language: {
        default: "en",
        translations: {
          en: translationEn,
          id: translationId,
          vi: translationVn,
          th: translationTh,
          ms: translationMy,
          zh: translationCn,
        },
      },
    });
  }, []);

  useLayoutEffect(() => {
    if (ready) {
      let nodeButtonReject = getElementByXpath(
        '//*[@id="cc-main"]/div[1]/div/div[2]/div[2]/div[1]/button[2]'
      );
      let nodeGroupButtonsPreferences = getElementByXpath(
        '//*[@id="cc-main"]/div[1]/div/div[2]/div[2]/div[2]'
      );
      if (nodeButtonReject && nodeGroupButtonsPreferences) {
        nodeGroupButtonsPreferences.appendChild(nodeButtonReject);
      }
      setReady(false);
    }
  }, [ready]);

  useEffect(() => {
    let node = setLanguage(apiLangs[language], true);
  }, [language]);

  return <></>;
};

export default CookieConsentCustom;
