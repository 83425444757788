import "./src/styles/global.css";
import WrapRootElement from "./src/components/WrapRootElement/WrapRootElement";
import WrapPageElement from "./src/components/WrapPageElement/WrapPageElement";
import "@mantine/core/styles.css";
import { MantineProvider } from "@mantine/core";
import { appTheme } from "./src/constants/mantine/appTheme";
import "@/fsd/app/styles/index.scss";
import { ReactElement, ReactNode } from "react";
import { PageProps } from "gatsby";

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
}: {
  routerProps: any;
  prevRouterProps: any;
}) => {
  return !(
    location.pathname.includes("/blog/category/") ||
    location.pathname.substr(-6) === "/blog/"
  );
};

export const wrapRootElement = WrapRootElement;

export const wrapPageElement = ({
  element,
  props,
}: {
  element: ReactElement;
  props: PageProps<object, { language: string }>;
}) => {
  return (
    <MantineProvider theme={appTheme}>
      <WrapPageElement element={element} props={props} />
    </MantineProvider>
  );
};
