import React, { useContext } from "react";
import { Box, Burger, Button } from "@mantine/core";

import { classNames } from "@/fsd/shared/lib/classNames/classNames";
import { useGetScreenSize } from "@/fsd/shared/lib/hooks/useGetScreenSize/useGetScreenSize";

import { Navbar } from "../../LayoutNavbar";
import { NavbarDrawer } from "../../NavbarDrawer";

import * as cls from "./Header.module.scss";
import LayoutLogo from "@/ui/Layout/LayoutLogo/LayoutLogo";
import { URLS } from "@/constants/common";
import RegisterButton from "@/ui/Buttons/RegisterButton/RegisterButton";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useAnalyticEventsHook } from "@/hooks/analyticEventsHook/useAnalyticEventsHook";
import { SelectLanguageIndex } from "@/fsd/features/selectLanguage";
import { DrawerStateContext } from "@/context/DrawerStateContext/DrawerStateContext";

interface HeaderProps {
  className?: string;
}

export const Header = ({ className }: HeaderProps) => {
  const { setValueDrawer, value } = useContext(DrawerStateContext);
  const { t, i18n } = useTranslation();
  const { clickLoginGA } = useAnalyticEventsHook();

  const { lgScreen } = useGetScreenSize();

  return (
    <div className={classNames(cls.Header, {}, [className])}>
      <LayoutLogo />
      {!lgScreen ? (
        <Navbar />
      ) : (
        <NavbarDrawer opened={value} setOpened={setValueDrawer} />
      )}
      <div className={cls.headerRight}>
        <SelectLanguageIndex />
        {!lgScreen && (
          <Box className={cls.headerButtons}>
            <RegisterButton miw={91} />
            <Button
              variant="outline"
              component="a"
              target="_blank"
              href={URLS.logIn + "?l=" + i18n.language}
              miw={82}
              onClick={() => clickLoginGA("landing", t("Log in"))}
            >
              {t("Log in")}
            </Button>
          </Box>
        )}
        {lgScreen && (
          <Burger
            color={"#787878"}
            opened={value}
            onClick={() => setValueDrawer(!value)}
          />
        )}
      </div>
    </div>
  );
};
