import React, { FC, useMemo, useState } from "react";
import { Group, Text } from "@mantine/core";

import * as cls from "./SelectLanguage.module.scss";
import { languages } from "@/constants/i18n";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { classNames } from "@/fsd/shared/lib/classNames/classNames";
import ChevronDown from "@/assets/icons/chevron.inline.svg";
import { useClickOutside } from "@mantine/hooks";
import { AppPath } from "@/routes/routes-enum";
import useSlugMappingsData from "@/hooks/useSlugMappingsData";

export type SelectLanguageProps = {
  className?: string;
  size?: "md" | "sm" | "lg";
  mobile?: boolean;
};

export const SelectLanguageBlog: FC<SelectLanguageProps> = ({
  className,
  size = "md",
  mobile,
}) => {
  const { originalPath, language, changeLanguage } = useI18next();

  const [opened, setOpened] = useState(false);

  const currentLang = useMemo(
    () => languages.find((item) => item.id === language),
    [language, languages]
  );

  let { slugMappingsData, postSlug } = useSlugMappingsData();

  const getFontSizeLabel = () => {
    switch (size) {
      case "lg":
        return 18;
      case "sm":
        return 14;
      default:
        return 16;
    }
  };

  const getFontSizeChevron = () => {
    switch (size) {
      case "md":
        return 15;
      case "sm":
        return 13;
      default:
        return 15;
    }
  };

  const handleLanguageChange = (lang: string) => {
    let nextSlug = slugMappingsData?.[postSlug || ""]?.[lang] || "";

    changeLanguage(lang, `${AppPath.blog}/${nextSlug}`);
  };

  const ref = useClickOutside(() => setOpened(false));

  return (
    <div className={classNames(cls.SelectLanguage, {}, [className])} ref={ref}>
      <Group
        // spacing={"xs"}
        // sx={{ cursor: "pointer" }}
        // position={"right"}
        onClick={() => setOpened((prev) => !prev)}
        wrap={"nowrap"}
        align="center"
        gap={"xs"}
      >
        <Text
          // ta={'right'}
          // color={"#1E1E1E"}
          // sx={[labelStyle, { fontFamily: "Mulish" }]}
          fz={getFontSizeLabel()}
        >
          {!mobile ? currentLang?.name : currentLang?.id.toLocaleUpperCase()}
        </Text>
        <ChevronDown
          size={getFontSizeChevron()}
          strokeWidth={2}
          color={"black"}
          className={classNames(
            cls.chevronIcon,
            { [cls.chevronIconActive]: opened },
            []
          )}
        />
      </Group>

      <div
        className={classNames(cls.menuDropdown, {
          [cls.active]: opened,
          [cls.notActive]: !opened,
        })}
      >
        <div
          className={classNames("", {}, [cls.firstItemDropdown])}
          onClick={() => setOpened(false)}
        >
          {currentLang?.name}
          <ChevronDown
            strokeWidth={2}
            color={"black"}
            className={classNames(
              cls.chevronIcon,
              { [cls.chevronIconActive]: opened },
              []
            )}
          />
        </div>
        {languages.map(
          (item) =>
            item.id !== currentLang?.id && (
              <div
                className={cls.menuDropdownItem}
                key={item.id}
                onClick={() => handleLanguageChange(item.id)}
              >
                {item.name}
              </div>
            )
        )}
      </div>
    </div>
  );
};
