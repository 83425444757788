module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fix-fouc/gatsby-browser.js'),
      options: {"plugins":[],"timeout":0,"minWidth":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e8a64fba18c05937db56d45e0bba4b51"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","id","th","vn","my","cn"],"siteUrl":"https://sellmatica.com","generateDefaultLanguagePage":true,"redirect":true,"i18nextOptions":{"debug":true,"fallbackLng":"en","defaultNS":"common"},"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/category/:category","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/category/:category/:page","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/category/:category/oldest/:page","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/category/:category/oldest","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/oldest/:page","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/:page","getLanguageFromPath":true},{"matchPath":"/:lang?/blog/oldest","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wp.sellmatica.com/graphql","schema":{"timeout":90000,"requestConcurrency":5,"previewRequestConcurrency":2,"perPage":10,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
