export const HOST = process.env.GATSBY_SITE_URL;
export const APP_URL = process.env.GATSBY_APP_URL;

export const URLS = {
  signUp: APP_URL + "/signup",
  logIn: APP_URL + "/login",
};

export const SOC: Record<string, any> = {
  email: "info@sellmatica.com",
  telegram: "https://t.me/sellmatica",
  linkedin: "https://www.linkedin.com/company/sellmatica",
  tiktok: "https://www.tiktok.com/@sellmatica",
  instagram: {
    id: "https://www.instagram.com/sellmatica.indonesia/",
  },
};
