export const AppPath = Object.freeze({
  main: "/",
  blog: "/blog",
  blogCategory: "/blog/category",
  faq: "/faq",
  about: "/about",
  career: "/career",
  contacts: "/contacts",
  subscription: "/price",
  policy: "/policy",
  terms: "/terms",

  externalAnalytics: "/tools/external-analytics",
  productAnalysis: "/tools/external-analytics/product-analysis",
  categoryAnalysis: "/tools/external-analytics/category-analysis",
  competitorAnalysis: "/tools/external-analytics/competitor-analysis",

  internalAnalytics: "/tools/internal-analytics",
  listingOptimization: "/tools/listing-optimization",
  promotionsAndAdvertising: "/tools/promotions-and-advertising",

  freeAssessment: "/offers/free-assessment",
});

export const AppDetailItems = Object.freeze({
  career: {
    contentCreator: "content-creator-editor",
  },
});
