import { createContext, FC, ReactNode, useState } from "react";

const DrawerStateContext = createContext<{
  value: boolean;
  setValueDrawer: (arg: boolean) => void;
}>({ value: false, setValueDrawer: () => null });

const DrawerStateContextProvider: FC<{
  values: boolean;
  children: ReactNode;
}> = ({ values, children }) => {
  const [drawerState, setDrawerState] = useState<boolean>(false);

  return (
    <DrawerStateContext.Provider
      value={{ value: drawerState, setValueDrawer: setDrawerState }}
    >
      {children}
    </DrawerStateContext.Provider>
  );
};

export { DrawerStateContextProvider, DrawerStateContext };
