import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Drawer } from "@mantine/core";

import { AccordionMenu } from "@/fsd/shared/ui/AccordionMenu";

import * as cls from "./NavbarDrawer.module.scss";
import { navigation } from "@/routes/navigation";
import { classNames } from "@/fsd/shared/lib/classNames/classNames";
import LayoutLogo from "@/ui/Layout/LayoutLogo/LayoutLogo";
import { SelectLanguageIndex } from "@/fsd/features/selectLanguage";
import { URLS } from "@/constants/common";
import RegisterButton from "@/ui/Buttons/RegisterButton/RegisterButton";
import { useAnalyticEventsHook } from "@/hooks/analyticEventsHook/useAnalyticEventsHook";

interface NavbarDrawerProps {
  className?: string;
  opened: boolean;
  setOpened: (open: boolean) => void;
}

const HEIGHT_HEADER_DRAWER = 55;

export const NavbarDrawer = ({
  className,
  opened,
  setOpened,
}: NavbarDrawerProps) => {
  const { t, i18n } = useTranslation("APPCON");

  const { clickLoginGA } = useAnalyticEventsHook();

  return (
    <Drawer
      styles={{
        title: { width: "100%", marginRight: 0 },
        body: { height: `calc(100% - ${HEIGHT_HEADER_DRAWER}px)` },
        // root: { padding: "16px 8px 16px 16px!important" },
      }}
      classNames={{ body: cls.DrawerBody }}
      lockScroll={true}
      opened={opened}
      onClose={() => setOpened(false)}
      title={
        <div className={cls.headerDrawer}>
          <LayoutLogo />
          <div className={cls.headerDrawerRight}>
            <SelectLanguageIndex mobile={true} />
          </div>
        </div>
      }
      size={340}
      position={"right"}
      padding={"md"}
    >
      <Box className={classNames(cls.NavbarDrawer, {}, [className])}>
        {/*{dashboard.map((item) => (*/}
        {/*  <UnstyledButton className={cls.btnBacklight}>*/}
        {/*    <NavLink name={item.title} href={item.href} />*/}
        {/*  </UnstyledButton>*/}
        {/*))}*/}
        <AccordionMenu items={navigation} />

        <Box className={cls.headerButtons}>
          <RegisterButton miw={91} />
          <Button
            variant="outline"
            component="a"
            target="_blank"
            href={URLS.logIn + "?l=" + i18n.language}
            miw={82}
            onClick={() => clickLoginGA("landing", t("Log in"))}
          >
            {t("Log in")}
          </Button>
        </Box>
        <div className={cls.emptyBlock}></div>
      </Box>
    </Drawer>
  );
};
