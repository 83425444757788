import { FC } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { AppShell, Box, Container, Grid, Text } from "@mantine/core";

import { AppPath } from "@/routes/routes-enum";

import LayoutLogo from "../LayoutLogo/LayoutLogo";
import FooterContacts from "./FooterContacts/FooterContacts";
import FooterNav from "./FooterNav/FooterNav";

import * as classes from "./LayoutFooter.module.css";

const LayoutFooter: FC = () => {
  const { t } = useTranslation();

  return (
    <AppShell.Footer
      withBorder={false}
      className={classes.root}
      bg={"black"}
      pos={"relative"}
      zIndex={0}
      c={"white"}
    >
      <Container size={"responsive"}>
        <Box className={classes.wrapperTop}>
          <Grid gutter={{ sm: 20, gutter: 0 }}>
            <Grid.Col span={{ base: 12, sm: 3 }}>
              <Box className={classes.content}>
                <LayoutLogo className={classes.logo} />

                <FooterContacts />
              </Box>
            </Grid.Col>

            <FooterNav />
          </Grid>
        </Box>

        <Box className={classes.wrapperBottom}>
          <Text size="sm" color="rgba(255, 255, 255, 0.75)">
            © {t("2023. Sellmatica. All rights reserved")}
          </Text>

          <Box className={classes.service}>
            <Text
              className={classes.serviceItem}
              fz="sm"
              component={Link}
              to={AppPath.terms}
            >
              {t("Terms of Service")}
            </Text>
            <Text
              className={classes.serviceItem}
              fz="sm"
              component={Link}
              to={AppPath.policy}
            >
              {t("Privacy Policy")}
            </Text>
          </Box>
        </Box>
      </Container>
    </AppShell.Footer>
  );
};

export default LayoutFooter;
