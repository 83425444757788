import { MantineThemeComponent, Button } from "@mantine/core";
import * as cls from "./Button.module.scss";
import { COLORS } from "@/constants/mantine/colors";
import { SHADOWS } from "@/constants/mantine/shadows";

export const ButtonComponent: Record<string, MantineThemeComponent> = {
  Button: Button.extend({
    styles: (theme: any, params: any) => {
      return {
        root: {
          borderRadius: 4,
          fontWeight: 600,
          fontSize: params.size === "md" ? theme.fontSizes.sm : undefined,
          height:
            params.variant === "subtle"
              ? "auto"
              : params.size === "md"
              ? 45
              : undefined,
          paddingLeft:
            params.variant === "subtle"
              ? 0
              : params.size === "md"
              ? 17
              : undefined,
          paddingRight:
            params.variant === "subtle"
              ? 0
              : params.size === "md"
              ? 17
              : undefined,
          borderWidth: params.variant === "subtle" ? 0 : undefined,
          color: params.variant === "default" ? COLORS.gray700 : undefined,
          borderColor:
            params.variant === "default" || params.variant === "outline"
              ? COLORS.gray300
              : undefined,
          backgroundColor:
            params.variant === "outline" ? theme.white : undefined,
          boxShadow: params.variant === "default" ? SHADOWS.xs : undefined,

          "&:hover": {
            color: params.variant === "outline" ? theme.white : undefined,
            backgroundColor:
              params.variant === "outline" ? theme.colors.violet[2] : undefined,
          },

          '&:[disabled], &[data-disabled="true"]': {
            color: params.variant === "outline" ? COLORS.gray : undefined,
            borderColor: params.variant === "outline" ? COLORS.gray : undefined,
            backgroundColor:
              params.variant === "outline" ? "transparent" : undefined,
          },
        },
      };
    },
  }),
};
