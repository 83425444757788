import axios from "axios";

import { apiLangs } from "@/constants/i18n";
import {
  GetCategoriesParams,
  GetPostParams,
  GetPostsParams,
} from "@/store/blog/types";

const axiosInstance = axios.create({
  baseURL: "https://wp.sellmatica.com/wp-json/wp/v2/",
});

export const api = {
  sendMail(subject: string, text: string) {
    return axiosInstance.request({
      method: "post",
      url: "https://app.sellmatica.com/api/email/internal/send",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify({
        secret: "bfc7d23c-250b-4527-92f8-8f2081a54f1f",
        subject,
        text,
      }),
    });
  },
};

export const blogApi = {
  getCategories({ language, per_page }: GetCategoriesParams) {
    return axiosInstance.request({
      method: "get",
      url: "categories",
      params: {
        lang: apiLangs[language],
        hide_empty: true,
        orderby: "count",
        order: "desc",
        per_page,
      },
    });
  },

  getPosts({
    language,
    page = 1,
    per_page = 8,
    order,
    categories,
  }: GetPostsParams) {
    return axiosInstance.request({
      method: "get",
      url: "posts",
      params: {
        lang: apiLangs[language],
        _embed: true,
        page,
        per_page,
        order,
        categories,
      },
    });
  },

  getPost({ language, slug }: GetPostParams) {
    return axiosInstance.request({
      method: "get",
      url: "posts",
      params: {
        lang: apiLangs[language],
        _embed: true,
        slug,
      },
    });
  },
};
