import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { Accordion, Grid, Stack, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import { BREAKPOINTS } from "@/constants/mantine/breakpoints";
import { bottomNavigation } from "@/routes/navigation";

import * as classes from "./FooterNav.module.css";

const FooterNav = () => {
  const { t } = useTranslation();

  const desktopView = useMediaQuery(`(min-width: ${BREAKPOINTS.sm})`);

  return (
    <>
      {bottomNavigation.map((section, index) => (
        <Grid.Col
          key={index}
          span={{ base: 12, sm: 2.5 }}
          offset={{ sm: index === 0 ? 4 : 0, base: 0 }}
          className={classes.col}
        >
          {desktopView ? (
            <>
              <Text fw={700} className={classes.title}>
                {t(section.title)}
              </Text>
              <Stack align="flex-start" gap={12} mt={9}>
                {section.items.map((item, index) => (
                  <Text
                    key={index}
                    className={classes.item}
                    component={Link}
                    to={item.href}
                  >
                    {t(item.title)}
                  </Text>
                ))}
              </Stack>
            </>
          ) : (
            <Accordion
              classNames={{
                control: classes.accordionControl,
              }}
            >
              <Accordion.Item value={section.title}>
                <Accordion.Control>{t(section.title)}</Accordion.Control>
                <Accordion.Panel>
                  <Stack align="flex-start" gap={12}>
                    {section.items.map((item, index) => (
                      <Text
                        key={index}
                        className={classes.item}
                        component={Link}
                        to={item.href}
                      >
                        {t(item.title)}
                      </Text>
                    ))}
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          )}
        </Grid.Col>
      ))}
    </>
  );
};

export default FooterNav;
