import { useMemo } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { graphql, useStaticQuery } from "gatsby";

const useSlugMappingsData = () => {
  const { originalPath, language } = useI18next();

  const data = useStaticQuery(graphql`
    query HeaderQueryLang {
      allWpPost(filter: { language: { code: { eq: ID } } }) {
        edges {
          node {
            title
            slug
            language {
              slug
            }
            translations {
              title
              slug
              language {
                slug
              }
            }
          }
        }
      }
    }
  `);

  const postSlug = originalPath.slice(0, -1).split("/").pop();

  let slugMappingsData: { [key: string]: { [key: string]: string } } =
    useMemo(() => {
      const dataSlugMappings = data.allWpPost.edges;
      let slugMappings: { [key: string]: { [key: string]: string } } = {};

      dataSlugMappings.forEach(
        (item: {
          node: {
            translations: { slug: string; language: { slug: string } }[];
            slug: string;
            language: { slug: string };
          };
        }) => {
          let chooseLanguage = "";

          let slugMappingsTranslateBlog: { [key: string]: string } = {};
          slugMappingsTranslateBlog[item.node.language.slug] = item.node.slug;

          if (item.node.language.slug === language) {
            chooseLanguage = item.node.slug;
          }

          item.node.translations.forEach(
            (translateBlog: { slug: string; language: { slug: string } }) => {
              slugMappingsTranslateBlog[translateBlog.language.slug] =
                translateBlog.slug;
              if (translateBlog.language.slug === language) {
                chooseLanguage = translateBlog.slug;
              }
            }
          );
          slugMappings[chooseLanguage] = slugMappingsTranslateBlog;
        }
      );
      return slugMappings;
    }, [data, language, originalPath]);

  const handleSlugMappingData = (lng: string) => {
    const dataSlugMappings = data.allWpPost.edges;
    let slugMappings: { [key: string]: { [key: string]: string } } = {};

    dataSlugMappings.forEach(
      (item: {
        node: {
          translations: { slug: string; language: { slug: string } }[];
          slug: string;
          language: { slug: string };
        };
      }) => {
        let chooseLanguage = "";

        let slugMappingsTranslateBlog: { [key: string]: string } = {};
        slugMappingsTranslateBlog[item.node.language.slug] = item.node.slug;

        if (item.node.language.slug === lng) {
          chooseLanguage = item.node.slug;
        }

        item.node.translations.forEach(
          (translateBlog: { slug: string; language: { slug: string } }) => {
            slugMappingsTranslateBlog[translateBlog.language.slug] =
              translateBlog.slug;
            if (translateBlog.language.slug === lng) {
              chooseLanguage = translateBlog.slug;
            }
          }
        );
        slugMappings[chooseLanguage] = slugMappingsTranslateBlog;
      }
    );
    return slugMappings;
  };

  return { slugMappingsData, handleSlugMappingData, postSlug };
};

export default useSlugMappingsData;
