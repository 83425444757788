import { FC } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { ActionIcon, Box } from "@mantine/core";

import { SOC } from "@/constants/common";
import EmailCircleIcon from "@/assets/icons/social/email-circle.inline.svg";
import TelegramCircleIcon from "@/assets/icons/social/telegram-circle.inline.svg";
import LinkedinCircleIcon from "@/assets/icons/social/linkedin-circle.inline.svg";
import InstagramCircleIcon from "@/assets/icons/social/instagram-circle.inline.svg";
import TiktokCircleIcon from "@/assets/icons/social/tiktok-circle.inline.svg";

import * as classes from "./FooterContacts.module.css";

const FooterContacts: FC = () => {
  const { language, t } = useI18next();

  return (
    <Box className={classes.root}>
      <ActionIcon
        className={classes.icon}
        variant="transparent"
        size={32}
        component="a"
        href={`mailto:${SOC.email}`}
        aria-label={t("Email")}
      >
        <EmailCircleIcon />
      </ActionIcon>

      <ActionIcon
        className={classes.icon}
        variant="transparent"
        size={32}
        component="a"
        target="_blank"
        href={SOC.telegram}
        aria-label="Telegram"
      >
        <TelegramCircleIcon />
      </ActionIcon>

      <ActionIcon
        className={classes.icon}
        variant="transparent"
        size={32}
        component="a"
        target="_blank"
        href={SOC.linkedin}
        aria-label="LinkedIn"
      >
        <LinkedinCircleIcon />
      </ActionIcon>

      {SOC.instagram[language] && (
        <ActionIcon
          className={classes.icon}
          variant="transparent"
          size={32}
          component="a"
          target="_blank"
          href={SOC.instagram[language]}
          aria-label="Instagram"
        >
          <InstagramCircleIcon />
        </ActionIcon>
      )}

      <ActionIcon
        className={classes.icon}
        variant="transparent"
        size={32}
        component="a"
        target="_blank"
        href={SOC.tiktok}
        aria-label="TikTok"
      >
        <TiktokCircleIcon />
      </ActionIcon>
    </Box>
  );
};

export default FooterContacts;
