import { useMediaQuery } from "@mantine/hooks";
import { BREAKPOINTS } from "@/constants/mantine/breakpoints";

export const useGetScreenSize = () => {
  const xlScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl})`);
  const lgScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg})`);
  const mdScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md})`);
  const smScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.sm})`);
  const xsScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xs})`);

  return { xlScreen, lgScreen, mdScreen, smScreen, xsScreen };
};
