import { createSlice } from "@reduxjs/toolkit";

import { getCategories, getPost, getPosts } from "./actions";
import { BlogState } from "./types";

const initialState: BlogState = {
  loading: false,
  error: false,
  posts: null,
  pagination: null,
  categories: null,
  post: null,
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getCategories
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });
    builder.addCase(getCategories.rejected, (state, action) => {
      state.error = action.payload || "System error";
    });

    // getPosts
    builder.addCase(getPosts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPosts.fulfilled, (state, action) => {
      state.loading = false;
      state.posts = action.payload.data;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(getPosts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "System error";
    });

    // getPost
    builder.addCase(getPost.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPost.fulfilled, (state, action) => {
      state.loading = false;
      state.post = action.payload[0];
    });
    builder.addCase(getPost.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "System error";
    });
  },
});

export const blogActions = {
  getCategories,
  getPosts,
  getPost,
  ...blogSlice.actions,
};

export default blogSlice.reducer;
