import { MantineThemeComponent, Accordion } from "@mantine/core";
import * as cls from "./Accordion.module.scss";

export const AccordionComponent: Record<string, MantineThemeComponent> = {
  Accordion: Accordion.extend({
    classNames: {
      control: cls.control,
      item: cls.item,
      label: cls.label,
      chevron: cls.chevron,
      content: cls.content,
    },
  }),
};
