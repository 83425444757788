import { useTranslation } from "react-i18next";
import { useEffect } from "react";

declare global {
  interface Window {
    dataLayer: any;
  }
}

const useAnalyticEventsHook = (flag?: boolean) => {
  // const { user } = useAppSelector((store) => store.auth);
  // const location = useLocation();
  const { i18n, t } = useTranslation();

  const pageViewGA = () => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push({
      login: "false",
      language: i18n.language,
      event: "ga4event",
      event_name: "page_view",
    });
  };

  useEffect(() => {
    if (flag) {
      pageViewGA();
    }
  }, []);

  const clickLoginGA = (
    scope: "landing" | "tools" | "offers",
    clickText?: string
  ) => {
    window.dataLayer.push({
      login: "false",
      language: i18n.language,

      event: "ga4event",
      event_name: "click",
      scope: scope,
      priority: "1",
      event_type: "product",
      category: "login",
      click_text: clickText || t("Log in"),
    });
  };

  const clickRegisterGA = (
    scope: "landing" | "tools" | "offers",
    clickText?: string
  ) => {
    window.dataLayer.push({
      login: "false",
      language: i18n.language,

      event: "ga4event",
      event_name: "click",
      scope: scope,
      priority: "1",
      event_type: "marketing",
      category: "sign_up",
      click_text: clickText || t("Sign up"),
    });
  };

  const clickLearnMoreGA = (clickText: string) => {
    window.dataLayer.push({
      login: "false",
      language: i18n.language,

      event: "ga4event",
      event_name: "click",
      scope: "tools",
      priority: "1",
      event_type: "marketing",
      category: "learn_more",
      click_text: clickText,
    });
  };

  const clickRequestFreeAuditGA = (clickText: string) => {
    window.dataLayer.push({
      login: "false",
      language: i18n.language,

      event: "ga4event",
      event_name: "click",
      scope: "offers",
      priority: "1",
      event_type: "marketing",
      category: "request_audit",
      click_text: clickText,
    });
  };

  const successSendingRequestFreeAuditGA = () => {
    window.dataLayer.push({
      login: "false",
      language: i18n.language,

      event: "ga4event",
      event_name: "form",
      scope: "offers",
      priority: "1",
      event_type: "marketing",
      category: "request_audit",
      action: "send",
      form_id: "request-audit-form",
    });
  };

  return {
    clickLoginGA,
    clickRegisterGA,
    clickLearnMoreGA,
    clickRequestFreeAuditGA,
    successSendingRequestFreeAuditGA,
  };
};

export { useAnalyticEventsHook };
