import { GatsbyBrowser } from "gatsby";
import { Provider } from "react-redux";

import { store } from "@/store";

const WrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <Provider store={store}>
      {/*<AppFonts />*/}
      {element}
    </Provider>
  );
};

export default WrapRootElement;
