import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { blogApi } from "@/api/api";

import { GetCategoriesParams, GetPostParams, GetPostsParams } from "./types";

export const getCategories = createAsyncThunk<
  any,
  GetCategoriesParams,
  { rejectValue: string }
>(
  "blog/getCategories",
  async (params: GetCategoriesParams, { rejectWithValue }) => {
    try {
      const { data } = await blogApi.getCategories(params);
      return data;
    } catch (e) {
      const { response } = e as AxiosError;
      const { data, statusText }: any = response;

      return rejectWithValue(data?.message || statusText);
    }
  }
);

export const getPosts = createAsyncThunk<
  any,
  GetPostsParams,
  { rejectValue: string }
>("blog/getPosts", async (params: GetPostsParams, { rejectWithValue }) => {
  try {
    const { data, headers } = await blogApi.getPosts(params);
    const pagination = {
      page: params.page,
      per_page: params.per_page,
      total_pages: Number(headers["x-wp-totalpages"]),
    };
    return { data, pagination };
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});

export const getPost = createAsyncThunk<
  any,
  GetPostParams,
  { rejectValue: string }
>("blog/getPost", async (params: GetPostParams, { rejectWithValue }) => {
  try {
    const { data } = await blogApi.getPost(params);
    return data;
  } catch (e) {
    const { response } = e as AxiosError;
    const { data, statusText }: any = response;

    return rejectWithValue(data?.message || statusText);
  }
});
