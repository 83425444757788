import { Accordion, MantineThemeOverride, rem, Title } from "@mantine/core";

import LinkIcon from "@/assets/icons/link.svg";

import { BREAKPOINTS } from "./breakpoints";
import { COLORS } from "./colors";
import { SHADOWS } from "./shadows";
import { generateColors } from "@mantine/colors-generator";
import { AccordionComponent } from "./components/Accordion/Accordion";
import { ContainerComponent } from "@/constants/mantine/components/Container/Container";
import { ButtonComponent } from "@/constants/mantine/components/Button/Button";

export const appTheme: MantineThemeOverride = {
  // breakpoints: BREAKPOINTS,
  fontFamily:
    "Mulish, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
  black: COLORS.black,
  breakpoints: BREAKPOINTS,
  colors: {
    "violet-sellmatica": generateColors("rgb(161,134,223)"),
    // "violet-sellmatica": [
    //   "#724CC4",
    //   "#6849AC",
    //   "#614B92",
    //   "#5A4A7D",
    //   "#53476C",
    //   "#4C435E",
    //   "#4C435E",
    // ],
  },
  headings: {
    fontFamily:
      "Mulish, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "800",
    sizes: {
      h1: { fontSize: rem(93), lineHeight: "1.05", fontWeight: "800" },
      h2: { fontSize: rem(40), lineHeight: "1.25", fontWeight: "800" },
      h3: { fontSize: rem(30), lineHeight: "1.2", fontWeight: "800" },
      h4: { fontSize: rem(22), lineHeight: "1.2", fontWeight: "800" },
      h5: { fontSize: rem(24), lineHeight: "1.33333", fontWeight: "600" },
      h6: { fontSize: rem(22), lineHeight: "1.33333", fontWeight: "600" },
    },
  },
  primaryColor: "violet-sellmatica",
  cursorType: "pointer",

  lineHeights: { lg: "1.4" },
  spacing: {
    xs: rem(5),
    sm: rem(10),
    md: rem(15),
    lg: rem(20),
    xl: rem(40),
  },

  // globalStyles: () => ({}),

  components: {
    Title: Title.extend({
      styles: (theme: any, params: any) => ({
        root: {
          [`@media (max-width: ${BREAKPOINTS.sm})`]: {
            fontSize:
              params.size !== undefined
                ? undefined
                : params.element === "h2"
                ? 28
                : params.element === "h3"
                ? 26
                : params.element === "h5"
                ? 20
                : undefined,
            lineHeight:
              params.size !== undefined
                ? undefined
                : params.element === "h5"
                ? 1.5
                : undefined,
          },
        },
      }),
    }),

    List: {
      defaultProps: {
        spacing: 8,
      },
      styles: (theme: any, params: any) => ({
        root: {
          "ol&": {
            counterReset: "list 0",
          },

          ul: {
            marginTop: params.spacing,
          },
          ol: {
            marginTop: params.spacing,
          },
        },
        item: {
          "&:not(.mantine-List-withIcon)": {
            listStyle: "none",
            display: "flex",
            alignItems: "flex-start",

            "&::before": {
              minWidth: 26,
              marginLeft: -1,
              marginRight: 2,

              "ol &": {
                content: 'counter(list, decimal) "."',
                counterIncrement: "list",
              },
              "ul &": {
                content: '"•"',
                fontSize: "70%",
                lineHeight: 1.4,
                paddingLeft: "0.7rem",
                marginTop: "0.1rem",
                marginBottom: "-0.1rem",
              },
            },
          },
        },
      }),
    },

    Divider: {
      defaultProps: {
        color: "black",
        size: "sm",
      },
    },

    Badge: {
      defaultProps: {
        variant: "light",
        radius: 16,
      },
      styles: (theme: any, params: any) => ({
        root: {
          fontWeight: 500,
          fontSize: theme.fontSizes.sm,
          lineHeight: "24px",
          letterSpacing: 0,
          textTransform: "none",
          border:
            params.variant === "light" || params.variant === "dot"
              ? "none"
              : undefined,
          height: "auto",
          color:
            params.color === "violet"
              ? COLORS.primary700
              : params.color === "blue"
              ? COLORS.blue700
              : params.color === "indigo"
              ? COLORS.indigo700
              : params.color === "pink"
              ? COLORS.pink700
              : params.color === "orange"
              ? COLORS.orange700
              : params.color === "green"
              ? COLORS.green700
              : undefined,
          backgroundColor:
            params.color === "violet"
              ? COLORS.primary50
              : params.color === "blue"
              ? COLORS.blue50
              : params.color === "indigo"
              ? COLORS.indigo50
              : params.color === "pink"
              ? COLORS.pink50
              : params.color === "orange"
              ? COLORS.orange50
              : params.color === "green"
              ? COLORS.green50
              : undefined,

          "&::before": {
            backgroundColor:
              params.variant !== "dot"
                ? undefined
                : params.color === "blue"
                ? COLORS.blue500
                : params.color === "indigo"
                ? COLORS.indigo500
                : params.color === "pink"
                ? COLORS.pink500
                : params.color === "orange"
                ? COLORS.orange500
                : params.color === "green"
                ? COLORS.green500
                : undefined,
          },
        },
      }),
    },

    Paper: {
      styles: () => ({
        root: {
          borderRadius: 8,
          boxShadow: "0 0px 60px 0px rgba(163, 163, 165, 0.22)",
        },
      }),
    },

    Drawer: {
      defaultProps: {
        padding: 24,
      },
      styles: () => ({
        // header: {
        //   paddingRight: 40,
        //   marginBottom: 40,
        // },
        content: {
          borderRadius: 0,
        },
      }),
    },

    Modal: {
      defaultProps: {
        size: 524,
        centered: true,
        radius: 8,
      },
      styles: () => ({
        header: {
          margin: 0,
        },

        title: {
          "&:empty": {
            display: "none",
          },
        },

        close: {
          color: "#495057",
          width: 26,
          height: 26,
          position: "absolute",
          top: 22,
          right: 19,

          [`@media (max-width: ${BREAKPOINTS.sm})`]: {
            top: 11,
            right: 8,
          },

          "&:focus": {
            outline: "none",
          },

          svg: {
            width: 26,
            height: 26,
          },
        },

        body: {
          padding: "39px 46px 38px",

          [`@media (max-width: ${BREAKPOINTS.sm})`]: {
            padding: "20px 25px",
          },
          [`@media (max-width: ${BREAKPOINTS.xs})`]: {
            padding: "10px 5px",
          },
          [`@media (max-width: ${BREAKPOINTS.xxs})`]: {
            padding: "5px 0",
          },
        },
      }),
    },

    Tabs: {
      styles: () => ({
        root: {},
        tabsList: {
          flexWrap: "wrap",
          borderBottomWidth: 1,
        },
        tab: {
          // fontSize: theme.fontSizes.lg,
          color: COLORS.dark,
          padding: "0 4px 12px",
          marginBottom: -1,

          '&[aria-selected="true"]': {
            // color: theme.colors.violet[1],
          },
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      }),
    },

    Radio: {
      defaultProps: {
        size: "sm",
      },
      styles: () => ({
        inner: {
          // marginTop: params.size === "xs" ? 2 : undefined,
        },
        labelWrapper: {
          fontWeight: 500,
          fontSize: 16,
          lineHeight: "20px",
        },
      }),
    },

    Checkbox: {
      defaultProps: {
        size: "sm",
      },
      styles: () => ({
        labelWrapper: {
          fontWeight: 500,
          fontSize: 16,
          lineHeight: "20px",
        },
      }),
    },

    CheckboxGroup: {
      defaultProps: {
        offset: 18,
        spacing: 20,
      },
      styles: () => ({
        label: {
          // color: theme.black,
        },
        description: {
          fontSize: 12,
          // color: theme.fn.rgba(theme.black, 0.5),
          marginTop: 2,
        },
      }),
    },

    Carousel: {
      styles: () => ({
        indicator: {
          width: 16,
          height: 16,
          backgroundColor: "rgba(127, 86, 217, 1)",
          borderRadius: "50%",
        },
        indicators: {
          bottom: -35,
        },
      }),
    },

    Slider: {
      styles: () => ({
        track: {
          height: 2,

          "&::before": {
            // backgroundColor: theme.fn.rgba(theme.white, 0.5),
            borderRadius: 10,
          },
        },
        bar: {
          height: 4,
          top: -1,
          // backgroundColor: theme.fn.rgba(theme.white, 0.7),
        },
        thumb: {
          border: "none",
          height: 14,
          width: 14,
        },
        label: {
          fontSize: 24,
          fontWeight: 600,
          backgroundColor: "transparent",
          top: -35,
        },
      }),
    },

    TextInput: {
      defaultProps: {
        size: "md",
      },
      styles: (params: any) => ({
        label: {
          fontWeight: 700,
          fontSize: 14,
          lineHeight: 1.42857,
          marginBottom: 4,
        },
        input: {
          fontSize: 16,
          fontWeight: 500,
          borderRadius: 4,
          height:
            params.size === "md" ? 45 : params.size === "sm" ? 40 : undefined,
          paddingLeft:
            params.size === "md" ? 14 : params.size === "sm" ? 12 : undefined,
          paddingRight:
            params.size === "md" ? 14 : params.size === "sm" ? 12 : undefined,
        },
      }),
    },

    Textarea: {
      defaultProps: {
        size: "md",
      },
      styles: (params: any) => ({
        label: {
          fontWeight: 700,
          fontSize: 14,
          lineHeight: 1.42857,
          marginBottom: 4,
        },
        input: {
          fontWeight: 500,
          borderRadius: 4,
          paddingLeft: params.size === "md" ? 14 : undefined,
          paddingRight: params.size === "md" ? 14 : undefined,
          paddingTop: params.size === "md" ? "10px !important" : undefined,
          paddingBottom: params.size === "md" ? "10px !important" : undefined,
        },
      }),
    },

    Select: {
      defaultProps: {
        size: "md",
      },
      styles: (params: any) => ({
        input: {
          fontWeight: 500,
          borderRadius: 4,
          height: params.size === "md" ? 45 : undefined,
          paddingLeft: params.size === "md" ? 14 : undefined,
          paddingRight: params.size === "md" ? 14 : undefined,
        },
      }),
    },

    TypographyStylesProvider: {
      styles: () => ({
        root: {
          color: COLORS.gray600,

          "> *": {
            "&:first-child": {
              marginTop: 0,
            },

            "&:last-child": {
              marginBottom: 0,
            },
          },

          h3: {
            marginTop: 0,
            marginBottom: 20,

            [`@media (max-width: ${BREAKPOINTS.sm})`]: {
              fontSize: 24,
              fontWeight: 600,
              lineHeight: 1.33333,
              marginBottom: 16,
            },
          },

          h5: {
            marginTop: -16,
            marginBottom: 16,

            [`@media (max-width: ${BREAKPOINTS.sm})`]: {
              fontSize: 20,
              lineHeight: 1.5,
              marginTop: -8,
              marginBottom: 12,
            },
          },

          "h1, h2, h3, h4, h5": {
            color: COLORS.gray900,
          },

          blockquote: {
            fontWeight: 500,
            fontSize: 23,
            lineHeight: 1.56522,
            fontStyle: "italic",
            color: COLORS.gray900,
            borderLeftWidth: 2,
            borderLeftColor: COLORS.primary700,
            borderRadius: 0,
            padding: "8px 0 8px 20px",
            marginTop: 0,

            [`@media (max-width: ${BREAKPOINTS.sm})`]: {
              fontSize: 20,
              lineHeight: 1.5,
              paddingLeft: 18,
            },

            cite: {
              fontSize: 16,
              lineHeight: 1.5,
              color: COLORS.gray600,
              marginTop: 32,

              [`@media (max-width: ${BREAKPOINTS.sm})`]: {
                marginTop: 24,
              },
            },
          },

          a: {
            textDecoration: "underline",

            "&:hover": {
              textDecoration: "none",
            },
          },

          figure: {
            marginTop: 0,
            marginLeft: 0,
            marginRight: 0,

            img: {
              height: "auto",
              marginBottom: 0,
            },
          },

          figcaption: {
            fontSize: 14,
            lineHeight: "20px",
            color: COLORS.gray600,
            marginTop: 16,

            [`@media (max-width: ${BREAKPOINTS.sm})`]: {
              marginTop: 12,
            },

            "&::before": {
              content: "''",
              background: `url(${LinkIcon})`,
              display: "inline-block",
              verticalAlign: "top",
              width: 16,
              height: 16,
              marginTop: 2,
              marginRight: 6,
            },
          },

          img: {
            borderRadius: 12,
          },

          "p, blockquote, ul, ol, figure, img": {
            marginBottom: 48,

            [`@media (max-width: ${BREAKPOINTS.sm})`]: {
              marginBottom: 40,
            },
          },

          "ul, ol": {
            paddingLeft: 22,
            marginTop: 20,
          },
        },
      }),
    },

    Loader: {
      defaultProps: {
        size: "lg",
      },
    },
    ...ButtonComponent,
    ...AccordionComponent,
    ...ContainerComponent,
  },
};
