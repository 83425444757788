import { Button, ButtonProps } from "@mantine/core";
import { URLS } from "@/constants/common";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FC } from "react";
import { useAnalyticEventsHook } from "@/hooks/analyticEventsHook/useAnalyticEventsHook";

interface IRegisterButtonProps extends ButtonProps {
  className?: string;
  scope?: "landing" | "tools" | "offers";
}

const RegisterButton: FC<IRegisterButtonProps> = ({
  className,
  scope = "landing",
  ...mantine
}) => {
  const { t, i18n } = useTranslation();
  const { clickRegisterGA } = useAnalyticEventsHook();

  return (
    <Button
      component="a"
      target="_blank"
      className={className}
      href={URLS.signUp + "?l=" + i18n.language}
      onClick={() => clickRegisterGA(scope, t("Sign up"))}
      {...mantine}
    >
      {t("Sign up")}
    </Button>
  );
};

export default RegisterButton;
