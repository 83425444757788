// extracted by mini-css-extract-plugin
export var SelectLanguage = "SelectLanguage-module--SelectLanguage--242e5";
export var active = "SelectLanguage-module--active--d84bb";
export var chevronIcon = "SelectLanguage-module--chevronIcon--96321";
export var chevronIconActive = "SelectLanguage-module--chevronIconActive--fa8a8";
export var firstItemDropdown = "SelectLanguage-module--firstItemDropdown--c12fa";
export var hiddenF = "SelectLanguage-module--hiddenF--40215";
export var menuDropdown = "SelectLanguage-module--menuDropdown--1010f";
export var menuDropdownItem = "SelectLanguage-module--menuDropdownItem--f281c";
export var notActive = "SelectLanguage-module--notActive--ea914";
export var shows = "SelectLanguage-module--shows--5196d";