import { Accordion as AccordionM } from "@mantine/core";
import { NavLink } from "@/fsd/shared/ui/NavLink";

import * as cls from "./AccordionMenu.module.scss";
import { classNames } from "@/fsd/shared/lib/classNames/classNames";
import { useContext } from "react";
import { DrawerStateContext } from "@/context/DrawerStateContext/DrawerStateContext";
import { useTranslation } from "gatsby-plugin-react-i18next";

interface AccordionMenuProps {
  className?: string;
  items: any[];
}

export const AccordionMenu = ({ className, items }: AccordionMenuProps) => {
  const { setValueDrawer } = useContext(DrawerStateContext);
  const { t } = useTranslation();

  return (
    <div className={classNames(cls.AccordionMenu, {}, [className])}>
      <AccordionM
        defaultValue=""
        classNames={{
          label: cls.AccordionMenuLabel,
          chevron: cls.AccordionMenuChevron,
          control: cls.AccordionMenuControl,
          item: cls.AccordionMenuItem,
        }}
      >
        {items.map((item) => (
          <AccordionM.Item value={item.title} key={item.href}>
            <AccordionM.Control chevron={!item?.links && <></>}>
              {item.href ? (
                <NavLink
                  externalLink={item.externalLink}
                  name={t(item.title)}
                  href={item.href}
                  onClick={() => setValueDrawer(false)}
                />
              ) : (
                item.title
              )}
            </AccordionM.Control>
            {item?.links && (
              <AccordionM.Panel>
                <div className={cls.links}>
                  {item?.links.map((link: any) => (
                    <NavLink
                      externalLink={item.externalLink}
                      name={link.title}
                      href={link.href}
                      icon={link.icon}
                    />
                  ))}
                </div>
              </AccordionM.Panel>
            )}
          </AccordionM.Item>
        ))}
      </AccordionM>
    </div>
  );
};
