import { FC } from "react";
import { Link } from "gatsby-plugin-react-i18next";
import { useMatch } from "@reach/router";
import { Box, BoxProps } from "@mantine/core";

import { AppPath } from "@/routes/routes-enum";

import * as classes from "./LayoutLogo.module.css";

const LayoutLogo: FC<BoxProps> = ({ className, ...props }) => {
  // const match = useMatch(AppPath.main);

  return (
    <Box
      className={`${classes.root} ${className}`}
      {...props}
      component={Link}
      to={AppPath.main}
    >
      sellmatica
    </Box>
  );
};

export default LayoutLogo;
