import React from "react";
import { Box } from "@mantine/core";
import { useLocation } from "@reach/router";
import { SelectLanguage } from "@/fsd/features/selectLanguage/ui/SelectLanguage";
import { SelectLanguageBlog } from "@/fsd/features/selectLanguage/ui/SelectLanguageBlog";
import { useGetScreenSize } from "@/fsd/shared/lib/hooks/useGetScreenSize/useGetScreenSize";

export const SelectLanguageIndex = ({ mobile }: { mobile?: boolean }) => {
  const location = useLocation();
  const headerLangBlog = () => {
    return !!String(location.pathname).match("\\/blog\\/.");
  };

  const { smScreen } = useGetScreenSize();

  return (
    <div>
      {headerLangBlog() ? (
        <Box>
          <SelectLanguageBlog mobile={mobile || smScreen} />
        </Box>
      ) : (
        <Box>
          <SelectLanguage mobile={mobile || smScreen} />
        </Box>
      )}
    </div>
  );
};
