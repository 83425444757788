// import EnIcon from "@/assets/icons/languages/en.inline.svg";
// import MyIcon from "@/assets/icons/languages/my.inline.svg";
// import VnIcon from "@/assets/icons/languages/vn.inline.svg";
// import CnIcon from "@/assets/icons/languages/cn.inline.svg";
// import ThIcon from "@/assets/icons/languages/th.inline.svg";
// import IdIcon from "@/assets/icons/languages/id.inline.svg";

export const languages = [
  { id: "en", name: "English" },
  { id: "id", name: "Bahasa Indonesia" },
  { id: "vn", name: "Tiếng Việt" },
  { id: "th", name: "ภาษาไทย" },
  { id: "my", name: "Bahasa Melayu" },
  { id: "cn", name: "中文" },
];

export const apiLangs: Record<string, string> = {
  en: "en",
  id: "id",
  vn: "vi",
  th: "th",
  my: "ms",
  cn: "zh",
};

export const locales: Record<string, string> = {
  en: "en-US",
  id: "id-ID",
  vn: "vi-VN",
  th: "th-TH",
  my: "ms-MY",
  cn: "zh-CN",
};

export const apiCountries: Record<string, string> = {
  en: "Indonesia",
  id: "Indonesia",
  vn: "Vietnam",
  th: "Thailand",
  my: "Malaysia",
  cn: "Indonesia",
};
