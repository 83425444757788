import { AppPath } from "@/routes/routes-enum";

export const navigation = [
  {
    title: "External Analytics",
    href: AppPath.externalAnalytics,
  },
  {
    title: "Internal Analytics",
    href: AppPath.internalAnalytics,
  },
  {
    title: "Free Assessment",
    href: AppPath.freeAssessment,
  },
  {
    title: "Blog",
    href: AppPath.blog,
  },
  {
    title: "Price",
    href: AppPath.subscription,
  },
];

export const bottomNavigation = [
  // {
  //   title: "Solutions",
  //   items: [
  //     {
  //       title: "New Sellers",
  //       href: "",
  //     },
  //     {
  //       title: "Experienced Sellers",
  //       href: "",
  //     },
  //     {
  //       title: "Brands",
  //       href: "",
  //     },
  //     {
  //       title: "Agencies & Consultants",
  //       href: "",
  //     },
  //     {
  //       title: "Retailers & Resellers",
  //       href: "",
  //     },
  //   ],
  // },
  // {
  //   title: "Use cases",
  //   items: [
  //     {
  //       title: "Find a product to sell",
  //       href: "",
  //     },
  //     {
  //       title: "Expand to marketplaces",
  //       href: "",
  //     },
  //     {
  //       title: "Improve my profitability",
  //       href: "",
  //     },
  //     {
  //       title: "Optimize my online presence",
  //       href: "",
  //     },
  //     {
  //       title: "Centralize my data",
  //       href: "",
  //     },
  //     {
  //       title: "Streamline my business",
  //       href: "",
  //     },
  //   ],
  // },
  {
    title: "Platform",
    items: [
      {
        title: "External Analytics",
        href: AppPath.externalAnalytics,
      },
      {
        title: "Internal Analytics",
        href: AppPath.internalAnalytics,
      },
      {
        title: "Storefronts & Listings",
        href: AppPath.listingOptimization,
      },
      {
        title: "Promotions & Advertising",
        href: AppPath.promotionsAndAdvertising,
      },
    ],
  },
  {
    title: "Company",
    items: [
      {
        title: "Blog",
        href: AppPath.blog,
      },
      {
        title: "Contact Us",
        href: AppPath.contacts,
      },
      {
        title: "Free Assessment",
        href: AppPath.freeAssessment,
      },
      {
        title: "About Us",
        href: AppPath.about,
      },
    ],
  },
];
